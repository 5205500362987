import React from 'react';
import Swal from 'sweetalert2';
import { Form } from 'react-bootstrap';
import withReactContent from 'sweetalert2-react-content';


import { Button, FormInput } from '../../form';
import {PostData} from '../../http'

export class Register extends React.Component {
   constructor(props) {
      super(props)
      this.state = {
         dataToSend: {
            name:"",
            email:"",
            coop:"",
            designation:""
         },
         isValidated: false,
         postAPI: 'coop/add'
      }

      this.baseState = {...this.state}

      this.resetState = () => {
         this.setState(() => ({
               ...this.baseState
            })
         )
      }

      this.onChangeHandler = (e) => {
         const {id, value} = e.target
         this.setState(state=> ({
            dataToSend:{
               ...state.dataToSend,
               [id]:value
            }
         }))
      }

      this.submitHandler = e => {
         const form = e.currentTarget;
         const MySwal = withReactContent(Swal);
         e.preventDefault();
         this.setState(()=>({isValidated:true}))
         if(!form.checkValidity()){
            e.stopPropagation();
         } else {
            PostData(
               props = {
                  postAPI: this.state.postAPI,
                  dataToSend: this.state.dataToSend
               }
            )
            this.resetState()
            this.setState(()=>({isValidated:false}))
         }
      }
   }

   render() {


      return (
         <>
            <div id="register-main-container">

               {/* <ImgBG id="register" src={image}/> */}

               <div id="register">
                  <div id="register-form-container">
                     
                     <div id="register-title-container">
                        <h1 id="register-title" className="text-highlight">Join our network!</h1>
                        <h6 id="register-subtitle">
                           Avail for early access to have ARCS on your cooperative while still in development.
                        </h6>
                     </div>

                     <Form noValidate validated={this.state.isValidated} onSubmit = {this.submitHandler}>
                        <Form.Row>
                           <FormInput 
                              id="name"
                              name="Name"
                              onChangeHandler={this.onChangeHandler}
                              type="text"
                              requried
                              value={this.state.dataToSend.name}
                           />
                        </Form.Row>
                        <Form.Row>
                           <FormInput 
                              id="email"
                              name="Email Address"
                              onChangeHandler={this.onChangeHandler}
                              type="text"
                              requried
                              value={this.state.dataToSend.email}
                           />
                        </Form.Row>
                        <Form.Row>
                           <FormInput 
                              id="coop"
                              name="Cooperative"
                              onChangeHandler={this.onChangeHandler}
                              type="textarea"
                              requried
                              value={this.state.dataToSend.coop}
                              as="textarea"
                              rows={1}
                           />
                        </Form.Row>
                        <Form.Row>
                           <FormInput 
                              id="designation"
                              name="Job Title"
                              onChangeHandler={this.onChangeHandler}
                              type="text"
                              requried
                              value={this.state.dataToSend.designation}
                           />
                        </Form.Row>
                        <div id="register-submit-privacy">
                           
                                 <Button size="lg" type="submit">Submit</Button>
                                 <h6 id="register-terms">
                                    By clicking submit,
                                    you agree to the
                                 <a href="https://iol.ph/privacy/"> Terms and Conditions </a>
                                 and
                                 <a href="https://iol.ph/privacy/"> Privacy Policy</a>
                                 .
                                 </h6>
                        </div>
                     </Form>
                  </div>
               </div>
            </div>
         </>
      )
   }
};
