import React from 'react';

import {Button} from '../../form/index';
import {ImgItem} from '../../img';
import image from '../../../assets/img/mainImage.svg';

export const MainPage = () => {

   return (
      <>
         <div id="main-container">
            
            <div id="second-container" >
               
               <div id="main-title-buttons" >
                  <div id="textDiv" >
                     <h1 className="text-highlight font-weight-bold">
                        Comprehensive System Solutions for Cooperatives
                     </h1>
                  </div >
                  <div id="main-page-buttons-largeScreen">
                     <a  href="#register-main-container">
                        <Button>Register Now</Button>
                     </a>
                     <a href="#player-main-container">
                        <Button variant="outline-primary"><span className="fe fe-play-circle "></span> Play Video</Button>
                     </a>
                  </div>
               </div>

               <ImgItem id="mainPage" src={image}/>

               <div className="main-page-buttons">
                  <a  href="#register-main-container" className="mb-3">
                     <Button>Register Now</Button>
                  </a>
                  <a href="https://youtu.be/isIJ8O-KJ_g">
                     <Button variant="outline-primary"><span className="fe fe-play-circle "></span> Play Video</Button>
                  </a>
               </div>
               
            </div>

            <div id="bottom-container" >
               <a href="#features-main-container"className="d-flex flex-row align-items-center w-100 text-decoration-none">
                  <span className="fe fe-arrow-down-circle mr-3" style={{fontSize:30}}></span>
                  <h3 className="mb-0">FEATURES</h3>
               </a>
            </div>

         </div>
      </>
   )
}
