import './App.css';
import { Header } from './components/header';
import { Footer } from './components/footer';
import { MainPage } from './components/desktop/';
import { Player } from './components/desktop/';
import { Features } from './components/desktop/';
import { Development } from './components/desktop/';
import { Register } from './components/desktop/';
import Survey from './components/survey'
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

const AppContent = () => (
  <div className="App">
    <Header />
    <MainPage />
    <Player />
    <Features />
    <Development />
    <Register />
    <Footer />
  </div>
)


function App() {
  return (
    <Router>
      <Switch>
        <Route path="/survey" component = {Survey}/>
        <Route path="/" component ={AppContent}/>
      </Switch>
    </Router>
  );
}

export default App;
