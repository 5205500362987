import React from 'react';
import { Button as BootstrapButton, Form, Col } from 'react-bootstrap';

export const Button = props => {
   const { children } = props

   return (
      <BootstrapButton variant={"primary"}  {...props} className={"btn-rounded mr-4 " + props.className} style={{ borderRadius: 20 }}>
         {children}
      </BootstrapButton>
   )
}

export const FormInput = props => {
   const {id, name, onChangeHandler, value, notRequired, type, as, rows} = props
   return (
      <Form.Group as={Col} controlId={id}>
         <Form.Label className="text-highlight font-weight-bold pl-2">{name}</Form.Label>
         <Form.Control 
            required={notRequired?"":true} 
            onChange={onChangeHandler}
            placeholder={name} 
            type={type}
            value={value}
            as={(as)?as:undefined}
            rows={rows?rows:undefined}
         />
      </Form.Group>
   )
}


