import React, {useState} from 'react';

import {useTransition, animated} from 'react-spring';


export const Sidebar = props => {
   const { children } = props
   const [showMenu, setShowMenu] = useState(false);
   
   
   const maskTransitions = useTransition(showMenu, null, {
      from: { position: 'fixed', opacity: 0},
      enter: { opacity: 1},
      leave: {opacity:0},
   });


   const menuTransitions = useTransition(showMenu, null, {
      from: { opacity: 0, transform: 'translateX(-100%'},
      enter: { opacity: 1, transform: 'translate(0%)'},
      leave: { opacity: 0, transform: 'translateX(-100%)'}
   })


   return (
      <nav>
         <h3 className="fe fe-menu" onClick = { () => setShowMenu(!showMenu)}><span></span></h3>

         {
            //MenuMask
             maskTransitions.map ( ({item, key, props}) => 
               item && 
               <animated.div 
                  key = {key}
                  style = {props}
                  className = "sidebar-mask"
                  onClick = {() => setShowMenu(false)}
               >
                  This is the menu
               </animated.div>
            )
         }


         {
            menuTransitions.map( ({item, key, props}) => 
               item && 
               <animated.div 
                  key = {key}
                  style = {props}
                  className = "sidebar-body"
                  onClick = {()=> setShowMenu(false)}
               >
                  {/* <NavigationMenu 
                     closeMenu = { () => setShowMenu(false)}
                  /> */}
                  {children}
               </animated.div>
            )
         }
      </nav>
   )
}

