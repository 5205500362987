import React from 'react';

export const TitleContainer = props => {
   const {title, id, subtitle}=props
   return(
      <div id="titleContainer">
         <h1 id={id?`${id}-title`: 'section-title'}>{title}</h1>
         <div id={id?`${id}-title-separator`: 'section-title-separator'} className={"title-separator " + (id!=='development'?"border-bottom-highlight":"")}></div>
         <h6 id={id?`${id}-subtitle`: 'section-title-subtitle'}>{subtitle}</h6>
      </div >
   )
}