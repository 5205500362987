import React from 'react';
import { TitleContainer } from '../../text';
import itemImage from '../../../assets/img/developmentImage.png';
import VectorWhite from '../../../assets/img/VectorWhite.png';
import VectorHollow from '../../../assets/img/VectorHollow.png';
import ProgressBar from '../../../assets/img/ProgressBar.png';
import { ImgItem } from '../../img';
export const Development = () => {

   return (
      <>
         <div id="development-main-container" >
            {/* <ImgBG id="development" src={image} /> */}
            <div id="development" className="flex-container  development-flex-container">

               <div className="flex-item-left">

                  <ImgItem id="development" src={itemImage} />
               </div>

               <div className="flex-item-right">
                  <div>
                     <TitleContainer
                        title="Development"
                        id="development"
                        subtitle="The illustration on the left maps out the development of ARCS."
                     />

                     <div >
                        <div id="development-legend" >
                           <div className="d-flex flex-row align-items-center">
                              <span className="mr-3"><img src={VectorWhite} alt="VectorWhite" /></span>
                              <h5>Completed</h5>
                           </div>
                           <div className="d-flex flex-row align-items-center">
                              <span className="mr-3"><img src={VectorHollow} alt="VectorHollow" /></span>
                              <h5>In Development</h5>
                           </div>
                        </div>

                        <div>
                           <h5 id="development-indicator-text">38% Done</h5>
                           <img src={ProgressBar} alt="ProgressBar" id="development-progress-bar" />
                        </div>
                     </div>
                  </div>

               </div>


            </div>
            
            <div id="bottom-container" >
               <a href="#register-main-container" className="d-flex flex-row align-items-center w-100 text-decoration-none">
                  <span className="fe fe-arrow-down-circle mr-3 text-white" style={{ fontSize: 30 }}></span>
                  <h3 className="mb-0 text-white">REGISTER</h3>
               </a>
            </div>
         </div>
      </>
   )
};
