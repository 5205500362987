import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

export const PostData = (props) => {

   const {postAPI, dataToSend} = props
   axios.post(postAPI, dataToSend)
   .then ( response => {
      const {status} = response
      console.log(status)
      if(response.status === 200 ){
         MySwal.fire({
            title: "Thank you for your interest!",
            text: "Check your email for updates.",
            icon: "success"
         })
      } else if (response.status === 201){
         MySwal.fire({
            title: "Email already in use!",
            text: "Please use another email.",
            icon: "warning"
         }) 
      }
   }).catch( error => {
      console.log(error);
      MySwal.fire({
         title: "Request Error",
         text:"Unable to process your request. Please try again after a few moments.",
         icon:"error"
      })
   })
}
