import React from 'react';
import {useSpring, animated} from 'react-spring';



export const ImgBG = props => {
   const {src, id} = props
   return(
      <img id={id?id+"-background":"background-image"} src={src} alt={id?id+"-bg":"background-image"} className="background-image"/>
   )
}

export const ImgItem = imageProps => {
   const calc = (x, y) => [ (y-window.innerHeight / 2 ) / 20,  (x-window.innerWidth / 2) / -100, 1.1 ]

   const trans = (x, y, s) => `perspective(900px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`
   
   const [props, set] = useSpring(()=> ({
      xys: [0, 0, 1],
      config: {
         mass: 5,
         tension: 350,
         friction: 40
      }
   }))
   const {src, id} = imageProps
   return(
      <animated.div
         className = "cards"
         onMouseMove = { ({clientX: x, clientY: y }) => set( {xys: calc(x,y)} )}
         onMouseLeave={()=>set({xys: [0, 0, 1]})}
         style={{ transform: props.xys.interpolate(trans) }}

      >
         <img id={id?id+"-item":"item-image"} src={src} alt={id?id+"-bg":"item-image"} className="item-image"/>
      </animated.div>

   )
}