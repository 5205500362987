import './header.css';
import React from 'react';
import { Link } from 'react-router-dom';
import { Sidebar } from '../sidebar/index';
import { Navbar, Nav } from 'react-bootstrap';
import logo from '../../assets/logo/logo_w_title.svg';

export const Header = () => {

   const Routes = [
      <>
         <Nav.Item>
            <Nav.Link href="#features-main-container" eventKey="1">Features</Nav.Link>
         </Nav.Item>
         <Nav.Item>
            <Nav.Link href="#development-main-container" eventKey="2">Development</Nav.Link>
         </Nav.Item>
         <Nav.Item>
            <Nav.Link href="#register-main-container" eventKey="3">Register Now</Nav.Link>
         </Nav.Item>
         <Nav.Item>
            <Nav.Link as={Link} href="#register-main-container" eventKey="4" to="/survey">Survey</Nav.Link>
         </Nav.Item>
      </>
   ]

   return (
      <Navbar className="header">
         <Navbar.Brand href="#main-container">
            <img id="header-logo" src={logo} alt="arcs-logo" />
         </Navbar.Brand>

         <div id="desktopHeader">
            {/* <Tab.Container defaultActiveKey="3"> */}
            <Nav>
               {Routes}
            </Nav>
            {/* </Tab.Container> */}
         </div>

         <div id="mobileHeader">

            <Sidebar>
               {/* <Tab.Container defaultActiveKey="3"> */}
               <Nav className="d-flex flex-column sidebar-items_container">
                  {Routes}
               </Nav>
               {/* </Tab.Container> */}
            </Sidebar>

         </div>



      </Navbar>
   )
}

// export default Header;