import React from 'react';
import {TitleContainer} from '../../text'


export const Features = () => {
   const generateItem = (icon, text) => (
      <span id="features-list-item">
         <span id="features-list-icons" className={`text-highlight fe ${icon} mr-3`}></span>
         <h6 id="features-list-text" className="mb-0 ">{text}</h6>
      </span>
   )
   return (
      <>
         <div id="features-main-container">

            {/* <ImgBG id="features" src={image}/> */}

            <div id="features">
               <TitleContainer
                  title="FEATURES"
                  id="features"
                  subtitle="Access ARCS anywhere, anytime to track membership, manage members’ financial accounts, create loan amortization, generate regulatory reports, and even assign roles to your different committees. "
               />
               <div id="features-list">
                  {generateItem('fe-users', 'Membership Database')}
                  {generateItem('fe-user', 'HR System(with payroll and disbursement)')}
                  {generateItem('fe-credit-card', 'Loan Module')}
                  {generateItem('fe-film', 'Pre-membership Education')}
                  {generateItem('fe-user-check', 'Multi-user Management')}
                  {generateItem('fe-arrow-right', 'Intra-cooperative Fund Transfer')}
                  {generateItem('fe-book', 'Accounting/Financial Reporting')}
                  {generateItem('fe-codepen', 'Inter-cooperative Network')}
                  {generateItem('fe-bookmark', 'Integrated CDA Reporting')}
                  {generateItem('fe-sidebar', 'Online Transaction Platform')}
                  {generateItem('fe-award', 'Credit Scoring Service')}
                  {generateItem('fe-info', 'Cooperative Finance Risk Assessment')}
                  {generateItem('fe-bar-chart', 'Data Analytics/API/Platform')}
               
               </div>

               
            </div>
            <div id="bottom-container" >
               <a href="#development-main-container"className="d-flex flex-row align-items-center w-100 text-decoration-none">
                  <span className="fe fe-arrow-down-circle mr-3 text-highlight" style={{fontSize:30}}></span>
                  <h3 className="mb-0 text-highlight">DEVELOPMENT</h3>
               </a>
            </div>
         </div>
      </>
   )
};
