import React from 'react';
import ReactPlayer from 'react-player/lazy';

export const Player = () => {
   return( 
      <div id="player-main-container">
         <ReactPlayer 
            url ="https://youtu.be/isIJ8O-KJ_g"
            className="react-player"
            width="90%"
            height="90%"
            id="video-player"
         />
      </div>
   )
}
